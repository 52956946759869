<template>
	<scale-box>
        <div class="area-box">
            <div class="box-top">
                <span class="span1">省内区域外</span>
                <span class="span2">返回</span>
            </div>
            <div class="guide-area">
                <el-table :data="tableData1" style="width: 1720px;overflow-y: auto;margin:0 auto;" :height="height + 'px'">
                    <el-table-column prop="sqrmc" label="商标申请总量" align="center" />
                    <el-table-column prop="zymc" label="有效量" align="center" />
                    <el-table-column prop="zllx" label="通过率" align="center"  />
                    <el-table-column prop="sqh" label="发明专利申请量" align="center"  />
                    <el-table-column prop="sqrmc" label="通过量" align="center" />
                    <el-table-column prop="zymc" label="发明通过率" align="center" />
                    <el-table-column prop="zllx" label="实用新型授权" align="center"  />
                    <el-table-column prop="zllx" label="外观授权" align="center"  />
                </el-table>
            </div>
            <div class="flex align-center" style="background-color:transparent;">
		      <el-pagination :current-page="from + 1" style="margin: 20px auto 0;width: 1200px;text-align:center;padding-top:10px"
		        :page-sizes="[20, 50, 100, 200]"
		        :page-size="size"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="total"
		        @size-change="pageSizeChanged"
		        @current-change="currentChanged"
		      />
		    </div>
        </div>
    
                   
  </scale-box>
</template>
<script>
import scaleBox from "../../components/data/sclae-box";
export default {
  name: '',
  data(){
    return {
	 from:0,
	  total:0,
	  size:20,
      height:880
    }
  },
 
  components: {
    scaleBox
  },
  watch:{
    
    
  },
  methods: {
	
	 getData(){
		
			// 	this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/'+url,
			// 		{
			// 		"sbmc": '',
			// 		"applicant": this.applant,
			// 		"regId": '',
			// 		"intCls": '',
			// 		"from": this.from,
			// 		"size":this.size,
			// 		"total": this.total,
			// 		"level": this.level,
			// 		"province": this.province,
			// 		"city": this.city,
			// 		"county":this.county,
			// 		"intCls":this.intCls,
			// 		"town":this.town
			// 		}
            // ).then((res) => {
            //     // console.log(res)
			// 	this.tableData.length = 0
			// 	this.tableData.push(...res.data.data)
			// 	if (res.data.data.length != 0) this.total = res.data.data[0].total	
            //   } 
			// )		
	 },
	
    
  },
   mounted() {
      this.getData();
    
    },
     beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#0e2140;')
        },
}
</script>

<style scoped>
	.area-box{
        width: 1920px;
        height: 1020px;
        background: url('../../../static/data.png') 100% no-repeat;
        background-size: cover;
        padding: 32px 0 28px;
        margin: 0 auto ;
    }
    .box-top{
        position: relative;
        text-align: center;
        margin-bottom: 20px;
    }
    .span1{
        font-size: 28px;
        color: #18F8FF
    }
    .span2{
        position: absolute;
        top:-8px;
        right:64px;
        display: inline-block;
        width: 120px;
        height: 40px;
        font-size: 20px;
        color: #ffffff;
        background: #002C8C;
        box-shadow: inset 0px 5px 15px 1px #1890FF;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        border: 1px solid #1890FF;
    }
    :deep(.el-table) {
    background-color: transparent !important;

  }
  :deep(.el-table__expanded-cell){
    background-color: transparent !important;
  }
	:deep(.el-table tr){
		background-color: transparent !important;
	}
	:deep(.el-table th){
		color: #FFFFFF;
    background-color: transparent !important;
  }
  :deep(.el-table td){
	color: #fff;
    background-color: transparent !important;
  }
:deep(.el-table__body tr),
:deep(.el-table__body td ){
  padding: 0;
  height: 80px;
  line-height: 80px;
}
:deep(.el-table td.el-table__cell),:deep(.el-table th.el-table__cell.is-leaf){
	border-bottom: 1px solid #69B1FF;
}
:deep(.el-table th.el-table__cell.is-leaf){
	height: 80px;
  line-height: 80px;
  border-top: 1px solid #69B1FF;
  border-bottom: 1px solid #69B1FF;
  font-size: 18px;
}
:deep(.el-table .cell){
	font-size: 18px;
}
:deep(.el-table--enable-row-hover .el-table__body tr:hover>td) {
    background-color: #264C8F!important;
    opacity: 0.9;
 }
 :deep(.el-table::before){
	height: 0;
 }
 :deep(.el-pagination){
	height: 35px;
	border:1px solid #91CAFF;
	background: #1A3876;
	vertical-align: middle;
	color: #fff;
	border-radius:0 0 20px 20px ;
 }
 :deep(.el-pagination .btn-next),:deep(.el-pagination .btn-prev){
	color: #fff!important;
	background: transparent;
 }
 :deep(.el-pager){
	background: #1A3876;
 }
 :deep(.el-pager li){
	background: #1A3876;
 }
 :deep(.el-pagination__total),:deep(.el-pagination__jump){
	color: #fff!important;
 }
 :deep(.el-pager li:not(.disabled).active),:deep(.el-pager li:hover){
        color: #18F8FF !important;
    }
:deep(.el-pager li.btn-quickprev),:deep(.el-pager li.btn-quicknext){
		color: #fff !important;
	}
    .flex :deep(.el-input__inner){
	border:1px solid #91CAFF!important;
	color: #fff !important;
	padding-left: 0!important;
	background: transparent!important;
}
</style>


